.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.PS-logo {
  height: 15vh;
  pointer-events: none;
}
@media (max-width: 768px) {
  .PS-logo {
    height: 10vh; /* Adjust the height for mobile devices */
  }
}

.ps-justified-text {
  color: white;
  text-align: center;
  /*font-family: Arial, sans-serif;*/
  font-family: 'Baloo 2', cursive;
  font-size: 18px;
  
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.head3-justified-text{
  color: white;
  text-align: center;
  /*font-family: Arial, sans-serif;*/
  font-family: 'Baloo 2', cursive;
  font-size: 25px;
  max-width: 800px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.bottom-justified-text{
  color: white;
  text-align: center;
  /*font-family: Arial, sans-serif;*/
  font-family: 'Baloo 2', cursive;
  font-size: 20px;
  max-width: 800px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
